import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { chunk, isEqual } from 'lodash';
import classnames from 'classnames';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import TableRow from './TableRow';
import ErrorBoundary from '../../pages/layout/ErrorBoundary';
import TableHeader from './TableHeader';
import TablePagination from './TablePagination';
import TableLoading from './TableLoading';
import TableCheckbox from './TableCheckbox';
import TableOptions from './TableOptions';
import { ColumnType } from './ColumnOptions';
import { getDisabledSelectedRows, getEnabledRows, getEnabledSelectedRows } from './tableUtils';
import { getInitialFilters } from '../../services/FiltersService';
import { copyLocationParams, updateQueryParam, updateSortByParams } from '../../services/SearchParamsService';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../consts/table';

import './table.scss';

export default function Table({
  columns,
  type,
  data,
  sortBy = [],
  options = {},
  isLoading = false,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  classNames,
  onRowSelected = () => {},
  totalCount,
  backendPagination = true,
  shouldScrollOnPagination = true,

  // filters
  fetchDataWithFilters = () => {},
  defaultFilters,
  extraCsvFilters,
  customFilters,
  excludeFilters,
  filtersOptions = {},
  getFiltersSelector,
  mapFilterValue,
  mapFilterFunction,

  // export
  exportPath,
  exportFileName,

  // search
  defaultSearchColumn,
  defaultSearchColumnName,

  // contextual actions
  getElementId,
  contextualActions,
  isRowDisabled = () => true,
  initialSelectedRows,
  showSelectAll = false,

  // components
  emptyState,
  customFooter,
  extraTableOptions,
}) {
  const memoizedDefaultFilters = useMemo(() => defaultFilters || [], [defaultFilters]);
  const memoizedContextualActions = useMemo(() => contextualActions || [], [contextualActions]);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters = getInitialFilters(searchParams, memoizedDefaultFilters);
  const [dataToShow, setDataToShow] = useState(data);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState(initialSelectedRows || []);
  const [filters, setFilters] = useState(initialFilters);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (initialSelectedRows && !selectedRows.length) {
      setSelectedRows(initialSelectedRows);
    }
  }, [initialSelectedRows]);

  const enrichedColumns = useMemo(
    () =>
      columns.map((column) => ({
        // let the column override the Header
        Header: column.backendKey ? t(`tables.${type}.columns.${column.backendKey}`) : ' ',
        ...column,
        sortDescFirst: [ColumnType.DATE, ColumnType.NUMBER].includes(column.type),
      })),
    [columns],
  );

  function getPageFromSearchParam() {
    const page = Number(searchParams.get('page') ?? 1);
    return page;
  }

  const showPagination = options.showPagination ?? true;
  const usePaginationSearchParam = options.usePaginationSearchParam ?? true;
  const showFilter = options.showFilter ?? true;
  const showHeaders = options.showHeaders ?? true;
  const showSort = options.showSort ?? true;
  const showRowSelection = options.showRowSelection || memoizedContextualActions.length > 0;
  const showFooter = !!data.length && (showPagination || customFooter);

  const sortParam = searchParams.get('sort');
  if (sortParam) {
    const params = chunk(sortParam.split(','), 2);
    // eslint-disable-next-line no-param-reassign
    sortBy = params.map(([id, desc]) => ({ id, desc: desc === 'desc' }));
  }

  const [sort, setSort] = useState(sortBy);
  const [pageIndex, setPageIndex] = useState(usePaginationSearchParam ? getPageFromSearchParam() : 1);

  const tablePlugins = showSort ? [useSortBy, usePagination] : [usePagination];
  const initialState = { sortBy, pageIndex: getPageFromSearchParam() - 1 };
  const pageCount = Math.ceil(totalCount / pageSize);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, gotoPage, setPageSize, state, rows } =
    useTable(
      {
        columns: enrichedColumns,
        manualSortBy: backendPagination,
        disableSortBy: isLoading,
        autoResetSortBy: false,
        manualPagination: backendPagination,
        pageCount,
        data: dataToShow,
        initialState,
        getRowId: getElementId,
        disableSortRemove: true,
      },
      ...tablePlugins,
    );

  function handleSelectRows(newSelected = []) {
    const disabledSelectedRows = getDisabledSelectedRows(rows, selectedRows, isRowDisabled).map((row) => row.id);
    setSelectedRows([...newSelected, ...disabledSelectedRows]);
  }

  function clearSelectedRows(shouldDeselectAll = false) {
    if (shouldDeselectAll || !isAllSelected) {
      handleSelectRows();
    }
  }

  function selectAllRows() {
    const enabled = getEnabledRows(rows, isRowDisabled).map((row) => row.id);
    handleSelectRows(enabled);
  }

  function updateSearchParams(currentFilters, currentSort, currentPage) {
    const newSearchParams = new URLSearchParams();
    const enrichedParams = copyLocationParams(searchParams, newSearchParams);
    let searchParamsWithSort = updateSortByParams(enrichedParams, currentSort);

    if (backendPagination) {
      searchParamsWithSort = updateQueryParam(searchParamsWithSort, 'page', currentPage);
    }

    currentFilters.forEach((filter) => {
      searchParamsWithSort.append(`${filter.column}:${filter.type}`, filter.value);
    });

    if (!currentFilters.length) {
      searchParamsWithSort.set('noFilters', 'true');
    }

    setSearchParams(searchParamsWithSort, { replace: true });
    const [orderBy, order] = currentSort.length ? [currentSort[0].id, currentSort[0].desc ? 'desc' : 'asc'] : [];

    if (backendPagination) {
      fetchDataWithFilters(filters, orderBy, order, currentPage);
    }
  }

  function handlePageChange(pageNumber) {
    if (!backendPagination) {
      gotoPage(pageNumber);
    }
    setPageIndex(pageNumber + 1);
    if (shouldScrollOnPagination) {
      document.getElementsByClassName('table-header-right')?.[0]?.scrollIntoView({ block: 'center' });
    }
    clearSelectedRows();
  }

  useEffect(() => {
    setPageSize(pageSize);
  }, []);

  useEffect(() => {
    onRowSelected(selectedRows);
    if (!backendPagination) {
      const totalEnabledCount = getEnabledRows(rows, isRowDisabled).length;
      const enabledSelectedRows = getEnabledSelectedRows(rows, selectedRows, isRowDisabled);
      setIsAllSelected(totalEnabledCount && totalEnabledCount === enabledSelectedRows.length);
    }
  }, [selectedRows.length]);

  // required to show the relevant data when the data is changed
  useEffect(() => {
    setDataToShow(data);
  }, [JSON.stringify(data)]);

  // we need to save a reference and compare it inside the hook, otherwise the hook will be called infinitely
  const prevSortBy = useRef(sortBy);
  useEffect(() => {
    const sortByFromState = state.sortBy;
    if (isEqual(sortByFromState, prevSortBy.current)) {
      return;
    }

    prevSortBy.current = sortByFromState;
    clearSelectedRows();
    setSort(sortByFromState);
    setPageIndex(1);
  }, [state.sortBy]);

  useEffect(() => {
    // don't set params unless sort or filters are used
    if (showSort || showFilter) {
      updateSearchParams(filters, sort, pageIndex);
    }
  }, [sort, pageIndex, filters]);

  function handleFrontendPaginationSearch(newFilters) {
    const searchValue = newFilters.find((filter) => filter.column === defaultSearchColumn)?.value;
    if (searchValue) {
      const filteredData = data.filter((row) =>
        row[defaultSearchColumn].toLowerCase().includes(searchValue.toLowerCase()),
      );
      setDataToShow(filteredData);
    } else {
      setDataToShow(data);
    }
  }

  function handleFiltersChange(newFilters) {
    handlePageChange(0);
    setFilters(newFilters);

    if (!backendPagination) {
      handleFrontendPaginationSearch(newFilters);
    }
  }

  function overrideSingleFilter(filter, shouldRemove) {
    let newFilters;
    if (shouldRemove) {
      newFilters = filters.filter((f) => !(f.column === filter.column && f.type === filter.type));
    } else {
      newFilters = filters.filter((f) => f.column !== filter.column);
      newFilters.push(filter);
    }
    handleFiltersChange(newFilters);
  }

  function onContextualActions(selectedData) {
    const [orderBy, order] = sort.length ? [sort[0].id, sort[0].desc ? 'desc' : 'asc'] : [];
    return memoizedContextualActions.map((contextualAction) =>
      contextualAction(selectedData, filters, orderBy, order, pageIndex),
    );
  }

  function getColumnOptions(column) {
    const columnOptions = column && (customFilters || []).find((filter) => filter.Header === column.Header)?.options;
    if (columnOptions) {
      return columnOptions;
    }
    if (column && filtersOptions[column.backendKey]) {
      return filtersOptions[column.backendKey];
    }
    return [];
  }

  const pageEnabledRows = getEnabledRows(page, isRowDisabled);
  const enabledSelectedRows = getEnabledSelectedRows(page, selectedRows, isRowDisabled);
  const isAllRowsInPageSelected = pageEnabledRows.length && enabledSelectedRows.length === pageEnabledRows.length;

  return (
    <ErrorBoundary>
      <TableOptions
        getColumnOptions={getColumnOptions}
        showRowSelection={showRowSelection}
        showFilter={showFilter}
        mapFilterFunction={mapFilterFunction}
        mapFilterValue={mapFilterValue}
        customFilters={customFilters}
        excludeFilters={excludeFilters}
        columns={enrichedColumns}
        getFiltersSelector={getFiltersSelector}
        handleFiltersChange={handleFiltersChange}
        contextualActions={onContextualActions}
        exportPath={exportPath}
        exportFileName={exportFileName}
        type={type}
        selectedRows={selectedRows}
        clearSelectedRows={clearSelectedRows}
        rows={rows}
        extraCsvFilters={extraCsvFilters}
        defaultSearchColumn={defaultSearchColumn}
        defaultSearchColumnName={defaultSearchColumnName}
        extraTableOptions={extraTableOptions}
        backendPagination={backendPagination}
        showSelectAll={showSelectAll}
        isAllSelected={isAllSelected}
        selectAllRows={selectAllRows}
      />

      <div className={classnames('table-wrapper', { pagination: !!showPagination })}>
        <div className="table-content">
          <table {...getTableProps()} className={`table ${classNames}`}>
            <thead>
              {showHeaders &&
                headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                    {showRowSelection && (
                      <th {...{ style: { width: '48px', height: '48px' } }}>
                        <TableCheckbox
                          disabled={!pageEnabledRows.length}
                          checked={isAllRowsInPageSelected}
                          onChange={({ target: { checked } }) => {
                            if (checked) {
                              handleSelectRows(pageEnabledRows.map((row) => row.id));
                            } else {
                              clearSelectedRows(true);
                            }
                          }}
                        />
                      </th>
                    )}
                    {headerGroup.headers.map((column) => (
                      <TableHeader
                        key={column.id}
                        column={column}
                        showSort={showSort}
                        showFilter={showFilter}
                        columnOptions={getColumnOptions(column)}
                        filtersOptions={filtersOptions}
                        overrideSingleFilter={overrideSingleFilter}
                        filter={filters.find((f) => f.column === column.backendKey)}
                      />
                    ))}
                  </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classnames({ 'no-footer': !showFooter })}>
              {isLoading
                ? [...Array(pageSize)].map((value, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={i}>
                      {[...Array(enrichedColumns.length)].map((val, colIndex) => (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <td key={enrichedColumns[colIndex]?.backendKey || enrichedColumns[colIndex]?.accessor}>
                          <TableLoading />
                        </td>
                      ))}
                    </tr>
                  ))
                : page.map((row) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        key={row.id}
                        selectedRows={selectedRows}
                        showRowSelection={showRowSelection}
                        isRowDisabled={isRowDisabled}
                        row={row}
                        setSelectedRows={setSelectedRows}
                        getFiltersSelector={getFiltersSelector}
                        handleFiltersChange={handleFiltersChange}
                      />
                    );
                  })}
            </tbody>
          </table>
        </div>
        {!data.length && !isLoading && <EmptyTable>{emptyState}</EmptyTable>}
        {showFooter && (
          <div className="table-footer">
            {!!showPagination && (
              <TablePagination
                page={pageIndex - 1}
                pageCount={pageCount}
                gotoPage={handlePageChange}
                showSelectedRows={!!memoizedContextualActions.length}
                selectedRowsCount={selectedRows.length}
                clearSelectedRows={clearSelectedRows}
                isLoading={isLoading}
              />
            )}
            {customFooter}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

function EmptyTable({ children }) {
  return <div className="empty-table-wrapper">{children}</div>;
}
