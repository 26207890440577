import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { LinkRenderer } from '../../../../../components/remediation/RemediationModal';
import Input from '../../../../../components/input/Input';

export default function InputWithInstructions({
  value,
  setValue,
  placeholder,
  description,
  instructions,
  type = 'text',
  error,
}) {
  const [openInstructions, setOpenInstructions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="input-with-instructions">
      <Input value={value} setValue={setValue} placeholder={placeholder} type={type} error={error} />
      <div className="text description">{description}</div>
      {instructions && (
        <>
          <span
            className="more-info"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpenInstructions(true);
            }}
          >
            How to get this data?
          </span>

          <Popover
            open={openInstructions}
            onClose={() => setOpenInstructions(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className="instruction-text">
              <Markdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
                {instructions}
              </Markdown>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
}
