import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  getHttpEndpoints,
  getHttpEndpointsFilters,
  getHttpEndpointsFiltersOptions,
  getHttpEndpointsLoading,
  getHttpEndpointsTotal,
} from '../../redux/selector/connectionSelector';
import {
  fetchHttpEndpointsFiltersOptions,
  updateHttpEndpointsFiltersAndRefetchSummaries,
} from '../../redux/slicers/connectionsSlicer';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';
import Table from '../../components/table/Table';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../inventory/empty_applications.svg';
import { ColumnType } from '../../components/table/ColumnOptions';
import PlatformName from '../../components/platform-name/PlatformName';
import { POWER_PLATFORM } from '../../consts/platforms';

export default function HttpEndpointsPage() {
  const dispatch = useDispatch();
  const data = useSelector(getHttpEndpoints);
  const isLoading = useSelector(getHttpEndpointsLoading);
  const totalCount = useSelector(getHttpEndpointsTotal);
  const filterOptions = useSelector(getHttpEndpointsFiltersOptions);

  const { t } = useTranslation(['common', 'connections']);

  useEffect(() => {
    dispatch(fetchHttpEndpointsFiltersOptions());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('tables.httpEndpoints.columns.domain'),
        accessor: 'domain',
        width: '25%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.httpEndpoints.columns.path'),
        accessor: 'path',
        width: '25%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.httpEndpoints.columns.method'),
        accessor: 'method',
        width: '10%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.httpEndpoints.columns.applicationName'),
        accessor: 'applicationName',
        width: '20%',
        Cell: ({ cell, row }) => {
          const summary = row.original;
          return (
            <OrganizationAwareLink to={`/applications/${summary.applicationId}?from=HTTP Endpoints`}>
              <span id={summary.id} data-tooltip-content={summary.description}>
                {cell.value}
                <Tooltip anchorId={summary.id} className="insight-summery-tooltip" />
              </span>
            </OrganizationAwareLink>
          );
        },
      },
      {
        Header: t('tables.httpEndpoints.columns.stepName'),
        accessor: 'stepName',
        width: '20%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        backendKey: 'accountName',
        width: '13%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value }) => (
          <EllipsisText text={<PlatformName platform={POWER_PLATFORM} name={value} />} overrideTooltipText={value} />
        ),
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'domain',
      desc: true,
    },
  ];

  return (
    <div className="connection-page">
      <Table
        columns={columns}
        data={data}
        options={{ showFilter: true, showHeaders: true }}
        type="httpEndpoints"
        sortBy={sortBy}
        exportPath="http-endpoints/summary/csv"
        exportFileName="Nokod HTTP Endpoints"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updateHttpEndpointsFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getHttpEndpointsFilters}
        onRowSelected={() => false}
        getElementId={(endpoint) => endpoint.id}
        filtersOptions={filterOptions}
        totalCount={totalCount}
        isLoading={isLoading}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('httpEndpoints.noData', { ns: 'connections' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="domain"
        defaultSearchColumnName={t('tables.httpEndpoints.columns.domain')}
      />
    </div>
  );
}
