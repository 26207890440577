import { useState } from 'react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import CloseableModal from '../../../../../components/modal/ClosableModal';
import Button from '../../../../../components/buttons/button/Button';
import { LinkRenderer } from '../../../../../components/remediation/RemediationModal';
import { AzurePermissions, getOnboardingToken, isTokenExpired } from '../../../../../auth/azure-auth-config';
import {
  createPowerPlatformIntegration,
  resetPowerPlatformAccountState,
} from '../../../../../redux/slicers/accountSlicer';
import Loader from '../../../../../components/loader/Loader';
import {
  getPowerPlatformIntegrationErrorMessage,
  isPowerPlatformIntegrationLoading,
} from '../../../../../redux/selector/accountsSelector';
import PowerPlatformIntegrationError from '../PowerPlatformIntegrationError';
import { getTenantNameFromUrl } from '../../../../../services/OrgNameService';
import { getTokenData } from '../../../../../services/AuthService';

export default function PowerPlatformIntegrationAccountConfirmationModal({
  open,
  setOpen,
  clientId,
  platformTenantId,
  clientSecret,
  name,
}) {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(isPowerPlatformIntegrationLoading);
  const serverError = useSelector(getPowerPlatformIntegrationErrorMessage);
  const { user } = useAuth0();

  const [azureError, setAzureError] = useState(null);

  const error = serverError || azureError;
  const isConditionalAccessError = error && error.includes('AADSTS53003:');

  const { instance } = useMsal();

  async function getTokenAndDispatchAccountCreation() {
    try {
      const powerappsServiceToken = getTokenData(clientId, 'accessToken', AzurePermissions.POWER_APPS_SERVICE);
      const powerappsUserApplyToken = getTokenData(
        clientId,
        'accessToken',
        AzurePermissions.POWER_PLATFORM_USERS_APPLY,
      );

      let accessToken = powerappsServiceToken?.secret;

      if (isTokenExpired(powerappsServiceToken) || isTokenExpired(powerappsUserApplyToken)) {
        const result = await getOnboardingToken(instance);
        accessToken = result.accessToken;
      }

      const { payload } = await dispatch(
        createPowerPlatformIntegration({
          name,
          platformTenantId,
          clientId,
          clientSecret,
          accessToken,
          auditUserName: user.name,
          auditUserEmail: user.email,
        }),
      );
      if (payload) {
        navigate(`/${getTenantNameFromUrl()}/portal/settings/platforms/power-platform/${payload.account.id}/edit`);
      } else {
        setAzureError(error);
      }
    } catch (e) {
      setAzureError(e.message);
    }
  }

  return (
    <CloseableModal
      open={open}
      closeModal={() => {
        if (!isLoading) {
          dispatch(resetPowerPlatformAccountState());
          setOpen(false);
        }
      }}
    >
      <div className="title">{t('accountIntegration.title')}</div>
      {isLoading && <Loader />}
      {error && !isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <div>{t('accountIntegration.title')}</div>
          <div>{error}</div>
        </div>
      )}
      {isConditionalAccessError && (
        <div className="error centered">
          <div className="emoji">💔</div>
          <PowerPlatformIntegrationError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <div className="text-container">
            <div className="text">
              <Markdown components={{ a: LinkRenderer }}>{t('accountIntegration.message', { clientId })}</Markdown>
            </div>
          </div>
          <div className="buttons-row">
            <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
            <Button
              isFilled
              text={t('general.approve', { ns: 'common' })}
              onClick={getTokenAndDispatchAccountCreation}
            />
          </div>
        </>
      )}
    </CloseableModal>
  );
}
