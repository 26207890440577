import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import { useTranslation } from 'react-i18next';
import InputWithInstructions from './InputWithInstructions';
import Button from '../../../../../components/buttons/button/Button';
import PowerPlatformIntegrationAccountConfirmationModal from './PowerPlatformIntegrationAccountConfirmationModal';

import { isPowerPlatformIntegrationLoading } from '../../../../../redux/selector/accountsSelector';
import { setAzureConfigClientId } from '../../../../../redux/slicers/accountSlicer';
import AzureAuthConfig from '../../../../../auth/azure-auth-config';

import './power-platform-new-account-page.scss';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';

export default function PowerPlatformNewAccountPage() {
  const [name, setName] = useState('');
  const [platformTenantId, setPlatformTenantId] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const [nameHasError, setNameHasError] = useState(false);
  const [platformTenantIdHasError, setPlatformTenantIdHasError] = useState(false);
  const [clientIdHasError, setClientIdHasError] = useState(false);
  const [clientSecretHasError, setClientSecretHasError] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(isPowerPlatformIntegrationLoading);

  const { t } = useTranslation(['onboarding', 'common']);

  useEffect(() => {
    const allHaveValues = [name, platformTenantId, clientId, clientSecret].every((value) => !!value);
    const allWithoutErrors = [nameHasError, platformTenantIdHasError, clientIdHasError, clientSecretHasError].every(
      (value) => !value,
    );
    setCanSubmit(allHaveValues && allWithoutErrors);
  }, [
    name,
    platformTenantId,
    clientId,
    clientSecret,
    platformTenantIdHasError,
    clientIdHasError,
    clientSecretHasError,
  ]);

  function isUUID(value) {
    return !value || validate(value);
  }

  useEffect(() => {
    if (isUUID(platformTenantId)) {
      setPlatformTenantIdHasError(false);
    } else {
      setPlatformTenantIdHasError(true);
    }
  }, [platformTenantId]);

  useEffect(() => {
    if (isUUID(clientId)) {
      setClientIdHasError(false);
    } else {
      setClientIdHasError(true);
    }
  }, [clientId]);

  useEffect(() => {
    if (!clientSecret || clientSecret.length > 20) {
      setClientSecretHasError(false);
    } else {
      setClientSecretHasError(true);
    }
  }, [clientSecret]);

  useEffect(() => {
    if (!name || name.length < 100) {
      setNameHasError(false);
    } else {
      setNameHasError(true);
    }
  }, [name]);

  return (
    <>
      <Breadcrumbs current="Connect New Power Platform" />
      <div className="integration-inputs-body">
        {modalOpen && (
          <PowerPlatformIntegrationAccountConfirmationModal
            open={modalOpen}
            setOpen={setModalOpen}
            clientId={clientId}
            platformTenantId={platformTenantId}
            clientSecret={clientSecret}
            name={name}
          />
        )}
        <p>
          <h1>{t('welcome.welcomeToNokodSecurity')}</h1>
          <div className="text">{t('welcome.welcomeMessage')}</div>
        </p>
        <p>
          <InputWithInstructions
            value={name}
            setValue={setName}
            placeholder={t('tenantNameField.placeholder')}
            description={t('tenantNameField.description')}
            error={nameHasError ? t('tenantNameField.error') : null}
          />
          <InputWithInstructions
            value={platformTenantId}
            setValue={setPlatformTenantId}
            placeholder={t('tenantIdField.placeholder')}
            description={t('tenantIdField.description')}
            error={platformTenantIdHasError ? t('tenantIdField.error') : null}
          />

          <InputWithInstructions
            value={clientId}
            setValue={setClientId}
            placeholder={t('clientIdField.placeholder')}
            description={t('clientIdField.description')}
            error={clientIdHasError ? t('clientIdField.error') : null}
          />

          <InputWithInstructions
            value={clientSecret}
            setValue={setClientSecret}
            placeholder={t('clientSecretField.placeholder')}
            description={t('clientSecretField.description')}
            error={clientSecretHasError ? t('clientSecretField.error') : null}
            type="password"
          />
        </p>

        <div className="footer">
          <Button
            text={t('general.submit', { ns: 'common' })}
            onClick={() => {
              setModalOpen(true);
              AzureAuthConfig.setConfig(clientId, platformTenantId);
              dispatch(setAzureConfigClientId(clientId));
            }}
            disabled={!canSubmit || loading}
          />
        </div>
      </div>
    </>
  );
}
