import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import BuildIcon from '@mui/icons-material/Build';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AcknowledgeButton from '../aknowledge/AcknowledgeButton';
import ButtonWithIcon from '../buttons/button-with-icon/ButtonWithIcon';
import { isSuperAdmin } from '../../redux/selector/authSelector';
import { getAccountById } from '../../redux/selector/accountsSelector';
import AzureAuthConfig, { getLoginRequest } from '../../auth/azure-auth-config';
import AutoRemediateModal, { LinkRenderer } from './RemediationModal';

import logo from '../../pages/layout/navbar/logo_closed.svg';

import './remediation.scss';
import { setAzureConfigClientId } from '../../redux/slicers/accountSlicer';

export default function Remediation({ text = '', remediationActions, acknowledgeText, onAcknowledge, insights }) {
  const memorizedInsights = useMemo(() => insights || [], [insights]);
  const firstInsight = memorizedInsights.length ? memorizedInsights[0] : {};
  const { t } = useTranslation(['remediation']);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modelOpen, setModalOpen] = useState(false);
  const superAdmin = useSelector(isSuperAdmin);
  const accountId = firstInsight.scope?.accountId;
  const account = useSelector(getAccountById(accountId));
  // not always insight returns with active property
  const activeInsight = memorizedInsights.filter((i) => i.active !== false && !i.status.includes('CLOSED'));
  const { scopeSubType, platformName } = firstInsight;
  const isRemediationImplemented = !!(
    account &&
    activeInsight.some((i) => i.canRemediate) &&
    !!getLoginRequest(scopeSubType)
  );

  const remediateActionsVisible = (!!remediationActions && superAdmin) || isRemediationImplemented;
  const isInRemediation = memorizedInsights.some((i) => i.status === 'IN_REMEDIATION');
  const remediate = searchParams.get('remediate');

  useEffect(() => {
    if (remediate) {
      setModalOpen(true);
      searchParams.delete('remediate');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  function onRemediate() {
    const { clientId, platformTenantId } = account.data;
    AzureAuthConfig.setConfig(clientId, platformTenantId);
    dispatch(setAzureConfigClientId(clientId));
    setModalOpen(true);
  }

  return (
    <div className="remediation">
      <div className="icon-wrapper">
        <img src={logo} alt="logo" width={70} />
        <div>{t('remediation')}</div>
      </div>
      <div className="mitigation-wrapper">
        <div className="remediation-body">
          {text.split('\n\n').map((textPart) => (
            <Markdown remarkPlugins={[remarkGfm]} components={{ a: LinkRenderer }} key={textPart}>
              {textPart}
            </Markdown>
          ))}
        </div>
        <div className="actions-container">
          <AcknowledgeButton
            text={acknowledgeText}
            onAcknowledge={onAcknowledge}
            insights={memorizedInsights}
            buttonColor="green"
            enabled={!!activeInsight.length}
          />
          {remediateActionsVisible && (
            <>
              <AutoRemediateModal
                platform={platformName}
                isDemo={!isRemediationImplemented}
                insights={insights}
                modelOpen={modelOpen}
                setModalOpen={setModalOpen}
                remediationActions={remediationActions}
                scopeSubType={scopeSubType}
                accountId={accountId}
              />
              <ButtonWithIcon
                onClick={onRemediate}
                text={t('remediate')}
                color="blue filled"
                enabled={isRemediationImplemented && !isInRemediation}
                icon={<BuildIcon />}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
