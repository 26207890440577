import { useState } from 'react';
import { useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { POWER_PLATFORM } from '../../consts/platforms';
import PersonName from '../person-name/PersonName';
import { getAccountByPlatformName } from '../../redux/selector/accountsSelector';
import OrganizationAwareLink from '../organization-aware-link/OrganizationAwareLink';

import teams from '../../pages/data-source/connector-icons/teams.svg';
import './person-data.scss';

export default function PersonData({ owner, icon, linksBreadcrumbs = '' }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const powerAppsAccount = useSelector(getAccountByPlatformName(POWER_PLATFORM));
  const { name, email, metadata, guest, active, id } = owner || {};
  const { position, domain, address, phone } = metadata || {};
  const companyName = email?.match(/@(.*?)(\.)/)?.[1];
  const domainAddress = domain?.split('@')?.[1];
  const formattedAddress = address?.replace(/\r\n/g, '<br />');

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  function sendMessage() {
    const { platformTenantId } = powerAppsAccount.data;
    const teamsURL = `https://teams.microsoft.com/l/chat/0/0?tenantId=${platformTenantId}&users=${email}`;
    window.open(teamsURL, '_blank');
  }

  return (
    <div onMouseEnter={handlePopoverOpen}>
      <PersonName name={name || email} icon={icon} />
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CloseIcon className="close" fontSize="small" onClick={() => setOpen(false)} />
        <div className="person-data">
          <div className="person-info">
            <OrganizationAwareLink to={`/users/${id}?from=${linksBreadcrumbs}`} className="person-link">
              <div className="name">{name}</div>
            </OrganizationAwareLink>
            {position && <div>{`${position} at  ${companyName}`}</div>}
            {domainAddress && <div>{domainAddress}</div>}
            <a href={`mailto:${email}`}>{email}</a>
            {!!guest && (
              <div className="guest">
                <PersonPinCircleOutlinedIcon />
                <span>External user</span>
              </div>
            )}
          </div>
          {address && (
            <>
              <Divider />
              <div className="address">
                <PlaceOutlinedIcon />
                <Markdown rehypePlugins={[rehypeRaw]} className="text">
                  {formattedAddress}
                </Markdown>
              </div>
            </>
          )}
          {(powerAppsAccount || phone) && <Divider />}
          {phone && (
            <div className="phone">
              <PhoneOutlinedIcon />
              {phone}
            </div>
          )}
          {powerAppsAccount && (
            <div onClick={sendMessage} className="teams-link">
              <img src={teams} alt="teams" />
              Teams
            </div>
          )}
        </div>
        {!active && (
          <div className="deleted">
            <ErrorOutlineOutlinedIcon />
            <div className="deleted-text">User is not active</div>
          </div>
        )}
      </Popover>
    </div>
  );
}
