import { useState } from 'react';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import BasicTabs from '../../components/basic-tabs/BasicTabs';
import ConnectorsPage from './ConnectorsPage';
import ConnectionsPage from '../connection/connections/ConnectionsPage';
import useChangeTab from '../../hooks/useChangeTab';

import './data-source-page.scss';
import HttpEndpointsPage from './HttpEndpointsPage';

const CONNECTORS_TAB = 'Connectors';
const CONNECTIONS_TAB = 'Connections';
const HTTP_ENDPOINTS_TAB = 'HttpEndpoints';

const tabsNameToNumber = {
  [CONNECTORS_TAB]: 0,
  [CONNECTIONS_TAB]: 1,
  [HTTP_ENDPOINTS_TAB]: 2,
};

export default function DataSourcesPage() {
  const [selectedTab, setSelectedTab] = useState('');
  const changeTab = useChangeTab(selectedTab, setSelectedTab, CONNECTORS_TAB);

  const { t } = useTranslation(['connections']);

  return (
    <div className="data-sources-page">
      <BasicTabs
        initialTab={tabsNameToNumber[selectedTab]}
        tabsData={[
          {
            label: t('connectors.name'),
            onClick: () => changeTab(CONNECTORS_TAB),
          },
          {
            label: t('connections.name'),
            onClick: () => changeTab(CONNECTIONS_TAB),
          },
          {
            label: t('httpEndpoints.name'),
            onClick: () => changeTab(HTTP_ENDPOINTS_TAB),
          },
        ]}
        showDivider={false}
      />
      <Divider />
      <div className="tab-content">
        {selectedTab === CONNECTORS_TAB && <ConnectorsPage switchTab={() => changeTab(CONNECTIONS_TAB)} />}
        {selectedTab === CONNECTIONS_TAB && <ConnectionsPage switchTab={() => changeTab(CONNECTIONS_TAB)} />}
        {selectedTab === HTTP_ENDPOINTS_TAB && <HttpEndpointsPage />}
      </div>
    </div>
  );
}
