import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import classnames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { getRules, isRulesLoading, isRulesUpdateSuccessful } from '../../redux/selector/policySelector';
import { fetchRules, updateRule } from '../../redux/slicers/policySlicer';
import { StyledSwitch } from '../../components/StyledSwitch';
import BasicTabs from '../../components/basic-tabs/BasicTabs';
import PageHeader from '../../components/page-header/PageHeader';
import SeverityBox from '../../components/box/SeverityBox';
import { getIcon } from '../../icons/iconUtils';
import ButtonWithIcon from '../../components/buttons/button-with-icon/ButtonWithIcon';
import emptyRuleImg from './no_rules_icon.svg';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import { isAdmin, isSuperAdmin } from '../../redux/selector/authSelector';
import CloseableModal from '../../components/modal/ClosableModal';
import RuleConfiguration from './RuleConfiguration';
import Button from '../../components/buttons/button/Button';
import Toast from '../../components/toast/Toast';
import SeverityPicker from '../../components/severity-picker/SeverityPicker';
import { SINGULAR_FORM } from '../../consts/translations';

export default function PolicyPage() {
  const dispatch = useDispatch();
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const isAdminRole = useSelector(isAdmin);
  const rules = useSelector(getRules);
  const rulesLoading = useSelector(isRulesLoading);

  const { t } = useTranslation(['common', 'policy']);

  const canEdit = isSuperAdminRole || isAdminRole;

  useEffect(() => {
    if (!rules.length && !rulesLoading) {
      (async function fetchData() {
        dispatch(fetchRules());
      })();
    }
  }, []);

  const [selectedTab, setSelectedTab] = useState('vulnerability');
  const [data, setData] = useState(rules);

  useEffect(() => {
    setData(rules);
    const selectedRules = rules.filter((rule) => rule.type === selectedTab);
    setData(selectedRules);
  }, [rules]);

  useEffect(() => {
    const selectedRules = rules.filter((rule) => rule.type === selectedTab);
    setData(selectedRules);
  }, [selectedTab]);

  function getCount(type) {
    return rules.filter((rule) => rule.type === type).length || 0;
  }

  const tabs = [
    {
      label: (
        <TabHeader
          type="vulnerability"
          title={t('insights.vulnerability', { count: SINGULAR_FORM })}
          count={getCount('vulnerability')}
        />
      ),
      onClick: () => setSelectedTab('vulnerability'),
    },
    {
      label: <TabHeader type="compliance" title={t('insights.compliance')} count={getCount('compliance')} />,
      onClick: () => setSelectedTab('compliance'),
    },
    {
      label: <TabHeader type="governance" title={t('insights.governance')} count={getCount('governance')} />,
      onClick: () => setSelectedTab('governance'),
    },
    {
      label: <TabHeader type="malicious" title={t('insights.maliciousActivity')} count={getCount('malicious')} />,
      onClick: () => setSelectedTab('malicious'),
    },
  ];

  return (
    <div className="policy-page">
      <PageHeader title={t('policyRules', { ns: 'policy' })} />
      <div className={classnames('tabs-container', selectedTab)}>
        <BasicTabs tabsData={tabs} />
        {isSuperAdminRole && (
          <ButtonWithIcon
            icon={<AddIcon width={6} />}
            onClick={() => {}}
            text={t('addRule', { ns: 'policy' })}
            color="blue"
          />
        )}
      </div>
      {rulesLoading && (
        <div>
          {[...Array(10)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} variant="rectangular" animation="wave" />
          ))}
        </div>
      )}

      <table className="rule-table">
        {data.map((rule) => (
          <RuleRow key={rule.id} {...rule} canEdit={canEdit} />
        ))}
        {!rulesLoading && !data.length && (
          <div className="empty-table-wrapper">
            <TableEmptyContent
              img={emptyRuleImg}
              mainText={t('noRulesFound', { ns: 'policy' })}
              secondaryText={t('noRulesFoundDescription', { ns: 'policy' })}
            />
          </div>
        )}
      </table>
    </div>
  );
}

function CreateRuleConfigurationModal({ open, setOpen, name, description, severity, configuration }) {
  return (
    <CloseableModal open={open} closeModal={() => setOpen(false)}>
      <div className="rule-config-modal">
        <div className="rule-modal-title">
          {name}
          <SeverityBox severity={severity} />
        </div>
        <div className="rule-text-container row">
          <div className="text">{description}</div>
        </div>

        {configuration && <RuleConfiguration configuration={configuration} />}
      </div>
    </CloseableModal>
  );
}

function EnableDisableRuleModal({ open, setOpen, active, ruleId, ruleName, openInsights }) {
  const dispatch = useDispatch();
  const [toastOpen, setToastOpen] = useState(false);
  const updateSuccessful = useSelector(isRulesUpdateSuccessful);
  const { user } = useAuth0();
  const { t } = useTranslation(['policy', 'common']);

  const titleMessage = t(active ? 'disableRule' : 'enableRule', { ruleName });
  let message = t('disableRuleMessage', { ruleName });
  if (active && openInsights) {
    message = t('acknowledgeOpenInsightsMessage', { count: Number(openInsights) });
  }

  return (
    <>
      <Toast
        open={toastOpen}
        setOpen={setToastOpen}
        isSuccess={updateSuccessful}
        title={updateSuccessful ? t('ruleStatusUpdateSuccess') : t('ruleStatusUpdateFailed')}
        message={
          updateSuccessful ? t(active ? 'ruleEnabled' : 'ruleDisabled') : t('general.pleaseTryAgain', { ns: 'common' })
        }
      />
      <CloseableModal open={open} closeModal={() => setOpen(false)}>
        <div className="title">{titleMessage}</div>
        <div className="body">
          {active && <span>{message}</span>}
          <div className="buttons-row">
            <Button
              isFilled={false}
              text={t('insights.acknowledge', { ns: 'common' })}
              onClick={async () => {
                await dispatch(
                  updateRule([
                    ruleId,
                    {
                      active: !active,
                      auditUserEmail: user.email,
                      auditUserName: user.name,
                    },
                  ]),
                );
                setToastOpen(true);
                setOpen(false);
              }}
            />
            <Button isFilled text={t('general.cancel', { ns: 'common' })} onClick={() => setOpen(false)} />
          </div>
        </div>
      </CloseableModal>
    </>
  );
}

function SeverityChangeModal({ open, setOpen, severity, active, ruleId }) {
  const dispatch = useDispatch();
  const [toastOpen, setToastOpen] = useState(false);
  const updateSuccessful = useSelector(isRulesUpdateSuccessful);
  const [newSeverity, setNewSeverity] = useState(severity);
  const { user } = useAuth0();

  const { t } = useTranslation(['policy', 'common']);

  return (
    <>
      <Toast
        open={toastOpen}
        setOpen={setToastOpen}
        isSuccess={updateSuccessful}
        title={updateSuccessful ? t('severityChangedSuccessfully') : t('severityChangeFailed')}
        message={
          updateSuccessful
            ? t('severityChangedSuccessfullyMessage', { severity })
            : t('general.pleaseTryAgain', { ns: 'common' })
        }
      />
      <CloseableModal open={open} closeModal={() => setOpen(false)}>
        <div className="title">{t('changeSeverity')}</div>
        <div className="body">
          <div>{t('selectTheSeverity')}</div>
          <SeverityPicker initialSeverity={severity} onChange={(s) => setNewSeverity(s)} />
          <div className="buttons-row">
            <Button isFilled={false} text={t('general.close', { ns: 'common' })} onClick={() => setOpen(false)} />
            <Button
              isFilled
              text={t('insights.acknowledge', { ns: 'common' })}
              onClick={async () => {
                await dispatch(
                  updateRule([
                    ruleId,
                    {
                      active,
                      severity: newSeverity,
                      auditUserEmail: user.email,
                      auditUserName: user.name,
                    },
                  ]),
                );
                setToastOpen(true);
                setOpen(false);
              }}
            />
          </div>
        </div>
      </CloseableModal>
    </>
  );
}

function RuleRow({ id, openInsights, active, name, description, severity, configuration, canEdit }) {
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
  const [enableDisableModalOpen, setEnableDisableModalOpen] = useState(false);
  const [severityChangeModalOpen, setSeverityChangeModalOpen] = useState(false);

  const { t } = useTranslation(['common']);

  return (
    <>
      <EnableDisableRuleModal
        open={enableDisableModalOpen}
        setOpen={setEnableDisableModalOpen}
        ruleId={id}
        openInsights={openInsights}
        active={active}
        ruleName={name}
      />

      <SeverityChangeModal
        open={severityChangeModalOpen}
        setOpen={setSeverityChangeModalOpen}
        severity={severity}
        active={active}
        ruleId={id}
      />

      <CreateRuleConfigurationModal
        open={moreInfoModalOpen}
        setOpen={setMoreInfoModalOpen}
        name={name}
        description={description}
        severity={severity}
        configuration={configuration}
      />

      <tr className="rule-row">
        <TableCell>
          <StyledSwitch checked={active} disabled={!canEdit} onClick={() => setEnableDisableModalOpen(true)} />
          {name}
        </TableCell>
        <TableCell>{description}</TableCell>
        <TableCell
          className="clickable"
          onClick={() => {
            if (canEdit) {
              setSeverityChangeModalOpen(true);
            }
          }}
        >
          <SeverityBox severity={severity} />
          {canEdit && <EditIcon />}
        </TableCell>
        <TableCell>
          <div
            className="more-info-button"
            role="button"
            tabIndex={0}
            onClick={async () => setMoreInfoModalOpen(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setMoreInfoModalOpen(true);
              }
            }}
          >
            {t('general.moreInfo')}
          </div>
        </TableCell>
      </tr>
    </>
  );
}

function TableCell({ children, onClick = () => {}, className }) {
  return (
    <td>
      <div className={classnames('rule-cell', className)} onClick={onClick}>
        {children}
      </div>
    </td>
  );
}

// count is commented out until we have malicious activity rules, as we don't want to show the number 0
function TabHeader({ type, title }) {
  const icon = getIcon(type);
  return (
    <div className="tab-header">
      {icon}
      <div className="name">{title}</div>
      {/* <div className={'count'}>({count})</div> */}
    </div>
  );
}
